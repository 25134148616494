/* eslint-disable prettier/prettier */
import { Component } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '@portal/app.component';
import { RegistrationService } from '@portal/business-sign-up/services/registration.service';
import { defaultUser, IUser } from '@portal/shared/models/user.model';
import { environment } from 'src/environments/environment';
import { SignUpInfoDialogComponent } from '../sign-up-info-dialog/sign-up-info-dialog.component';
import { ArabicLanguage, DefaultLanguage } from '@portal/shared/services/language/language.model';
import { LanguageService } from '@portal/shared/services/language/language.service';
import { RoutingNavigateService } from '@portal/shared/services/routing-navigate/routing-navigate.service';

@Component({
    selector: 'app-login-toolbar',
    templateUrl: './login-toolbar.component.html',
    styleUrls: ['./login-toolbar.component.scss']
})
export class LoginToolbarComponent {
    currentLanguage = localStorage.getItem('lang') || DefaultLanguage;
    showSignUpAsCanteen = false;
    showEPortal = false;

    constructor(
        private modalService: NgbModal,
        public trans: TranslateService,
        private router: Router,
        private registrationService: RegistrationService,
        private languageService: LanguageService,
        private routingNavigateService: RoutingNavigateService,
    ) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd && event.url) {
                this.showSignUpAsCanteen = !environment.production && !(event.url.startsWith('/business-sign-up') || event.url.startsWith('/adult-signup-request'));
                this.showEPortal = !(event.url.startsWith('/adult-signup-request') || event.url.startsWith('/merchant-signup-request'));
                this.languageService.languageChanged().subscribe(lang => {
                    if (this.showEPortal || !lang?.length) {
                        return;
                    }
                    this.currentLanguage = lang === ArabicLanguage ? DefaultLanguage : ArabicLanguage;
                    this.onChangedLanguage();
                });
            }
        });
    }

    showSignUpInfo = () => {
        const modalRef = this.modalService.open(SignUpInfoDialogComponent, {
            size: 'md',
            backdrop: 'static'
        });
        modalRef.result.then().catch();
    };

    onChangedLanguage() {
        if (this.currentLanguage === DefaultLanguage) {
            this.languageService.updateLanguage(ArabicLanguage)
            this.currentLanguage = ArabicLanguage;
        } else {
            this.languageService.setDefault();
            this.currentLanguage = DefaultLanguage;
        }
    }

    get dropdownClass() {
        return AppComponent.textDir === 'rtl' ? 'start' : 'end';
    }

    clearStorage = () => {
        if (!this.showSignUpAsCanteen) {
            const user: IUser = this.registrationService.localStorageBUser || defaultUser;

            if (user && (user.schools?.length > 0 || user.canteens?.length > 0)) {
                this.registrationService.clearUser();
                this.registrationService.clearUserEmail();
            }
            localStorage.removeItem('businessUserType');
        }

        this.routingNavigateService.home();
    }
}
