import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DefaultLanguage, LanguageKey } from './language.model';

@Injectable({ providedIn: 'root' })
export class LanguageService {
    private subject$: BehaviorSubject<string> = new BehaviorSubject<string>(DefaultLanguage);

    constructor(private translate: TranslateService) {
        this.translate.addLangs(['en', 'ar']);
        this.translate.setDefaultLang('en');
        of(localStorage.getItem(LanguageKey)).subscribe((res) => {
            const language = res ?? DefaultLanguage;
            this.subject$.next(language);
        })
    }

    public updateLanguage(language: string): void {
        this.translate.use(language);
        this.subject$.next(language);
    }

    public setDefault(): void {
        return this.updateLanguage(DefaultLanguage);
    }

    public languageChanged(): Observable<string> {
        return this.subject$.asObservable();
    }
}
