/* eslint-disable prettier/prettier */
/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @angular-eslint/no-input-rename */
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITypeaheadSelectConfig } from '@portal/shared/models/typeahead-select.model';
import * as _ from 'lodash';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../component-base';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-typeahead-select',
    templateUrl: './typeahead-select.component.html',
    styleUrls: ['./typeahead-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TypeaheadSelectComponent),
            multi: true,
        },
    ],
})
export class TypeaheadSelectComponent extends ComponentBase implements ControlValueAccessor {
    @Input() id = '';
    @Input() formControlName = '';
    @Input() selectConfig!: ITypeaheadSelectConfig;
    @Input() disabled = false;
    @Output() change = new EventEmitter();

    data$: Observable<any>;
    searching = false;
    input$ = new Subject<string>();

    @Input('value') _value: any;
    get value() {
        return this._value;
    }
    set value(value) {
        this._value = value;
        this.onChange(value);
        this.onTouched();
    }

    private onChange: any = () => { };
    private onTouched: any = () => { };

    get placeHolderText() {
        return this.selectConfig.placeholder || '';
    }

    get typeToSearchText() {
        return this.selectConfig.typeToSearchText || 'Start typing to search...';
    }

    get loadingText() {
        return this.selectConfig.loadingText || 'Searching...';
    }

    get notFoundText() {
        return this.selectConfig.notFoundText || 'No records found';
    }

    get labelFieldName() {
        if (!this.selectConfig || !this.selectConfig.bindLabel) return '';
        const { bindLabel } = this.selectConfig;
        if (!bindLabel.includes('.')) return bindLabel;
        const fields: string[] = bindLabel?.split('.');
        return fields[fields.length - 1];
    }

    fieldText(item: any) {
        return this.extractFieldValue(item, this.selectConfig.bindLabel);
    }

    fieldValue(item: any) {
        if (this.selectConfig.bindValue === 'user._id'){
            return this.extractFieldValue(item, this.selectConfig.bindValue)
        }
        else {
            return item['_id'];
        }
    }

    constructor(private http: HttpClient) {
        super();
        this.data$ = concat(
            of([]), // default items
            this.input$.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                filter((search) => !!search),
                tap(() => (this.searching = true)),
                switchMap((search) =>
                    this.httpObservable(search).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.searching = false)),
                        map((res: any) => {
                            if (Array.isArray(res)) return res;
                            const responseKey =
                                this.selectConfig.api.responseKey;
                            if (
                                responseKey &&
                                res[responseKey] &&
                                Array.isArray(res[responseKey])
                            )
                                return res[responseKey];
                            return [];
                        })
                    )
                ),
                takeUntil(this.destroyed$)
            )
        );
    }

    private httpObservable(search: string) {
        const apiConfig = this.selectConfig.api;
        let apiUrl = apiConfig.url;
        let body = apiConfig.body;
        let queryParams: string[] = [];
        const searchKey = apiConfig.searchKey || 'search';
        if (apiConfig.queryParams) {
            if (!apiUrl.includes('?')) apiUrl += '?';
            queryParams = apiConfig.queryParams.split('&');
        }
        if (apiConfig.method == 'GET') {
            queryParams.push(`${searchKey}=${search}`);
        } else if (body) {
            body = {
                ...body,
                [searchKey]: search,
            };
        }
        apiUrl += queryParams.join('&');

        const requestURL = `${environment.API_BASE_PATH}/v${environment.API_VERSION}/${decodeURIComponent(apiUrl)}`.replace('//', '/');
        return this.http.request(apiConfig.method, requestURL, { body });
    }

    private extractFieldValue(obj: any, pattern: any): any {
        return _.get(obj, pattern);
        // if (typeof pattern == 'string')
        //     return this.extractFieldValue(obj, pattern.split('.'));
        // else if (pattern.length == 0) return obj;
        // else return this.extractFieldValue(obj[pattern[0]], pattern.slice(1));
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
